import "./bootstrap"

import { createApp, h } from "vue"
import { createInertiaApp } from "@inertiajs/vue3"
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist"
import routes from "./routes.json"
import { trail } from "momentum-trail"
import { modal } from "momentum-modal"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { LinguaVue } from "lingua-js"
import { Lingua } from "./lingua"
import vuetify from "./Plugins/vuetify"
import PerfectScrollbar from "vue3-perfect-scrollbar"
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"
import { VueMaskDirective } from "v-mask"
import VueApexCharts from "vue3-apexcharts"
import "vue3-emoji-picker/css"
import FloatingVue from "floating-vue"
import "floating-vue/dist/style.css"
import { VueReCaptcha } from 'vue-recaptcha-v3'

const vMaskV2 = VueMaskDirective
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind,
}

createInertiaApp({
    title: (title) => {
        return title ? `${title}` : "Springcast PRO"
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
    setup({ el, App, props, plugin }) {
        const captchaKey = props.initialPage.props.recaptcha_site_key;

        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(VueReCaptcha, { siteKey: captchaKey } )
            .directive("mask", vMaskV3)
            .use(vuetify)
            .use(VueApexCharts)
            .use(modal, {
                resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
            })
            .use(trail, { routes })
            .use(ZiggyVue)
            // .use(Vue3Lottie)
            .use(LinguaVue, {
                Lingua,
            })
            .use(FloatingVue, {
                themes: {
                    springcast: {
                        $extend: "tooltip",
                        $resetCss: true,
                        placement: "bottom-start",
                    },
                },
            })
            .use(PerfectScrollbar)
            .mount(el)
    },
    progress: {
        includeCSS: false,
    },
})
